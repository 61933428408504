import Image0 from "public/images/membership/Image-0.png";
import Image1 from "public/images/membership/Image-1.png";
import Image2 from "public/images/membership/Image-2.png";
import Image3 from "public/images/membership/Image-3.png";

const MEMBERSHIP_GUIDE_DATA = Object.freeze([
  {
    id: 1,
    title: "common:membershipGuide.steps.one.title",
    description: "common:membershipGuide.steps.one.description",
    image: Image0,
  },
  {
    id: 2,
    title: "common:membershipGuide.steps.two.title",
    description: "common:membershipGuide.steps.two.description",
    image: Image1,
  },
  {
    id: 3,
    title: "common:membershipGuide.steps.three.title",
    description: "common:membershipGuide.steps.three.description",
    image: Image2,
  },
  {
    id: 4,
    title: "common:membershipGuide.steps.four.title",
    description: "common:membershipGuide.steps.four.description",
    image: Image3,
  },
]);

export default MEMBERSHIP_GUIDE_DATA;
