import { Box, Typography, useMediaQuery, Grid, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Image from "next/image";
import Link from "next/link";
import { useTranslation } from "next-i18next";

import membershipGuide from "src/mock/membershipGuide";

type MembershipGuideProps = {
  showDetailsLink?: boolean;
  forDrawer?: boolean;
};

export const MembershipGuide = ({
  showDetailsLink = true,
  forDrawer = false,
}: MembershipGuideProps) => {
  const theme = useTheme();
  const mediumBreakpoint = useMediaQuery(theme.breakpoints.up("md"));

  const { t } = useTranslation();

  const Content = () => (
    <Grid container spacing={mediumBreakpoint ? 3 : 6}>
      {membershipGuide?.map((item) => (
        <Grid item lg={forDrawer ? 6 : 3} md={12} key={item.id}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 3,
            }}
          >
            <Box
              component="div"
              style={{
                width: "100%",
                position: "relative",
                height: mediumBreakpoint ? "170px" : "214px",
              }}
            >
              <Image
                src={item.image}
                alt={item.title}
                fill
                sizes="100vw"
                style={{
                  objectFit: "cover",
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 2,
              }}
            >
              <Typography component="h3" variant="mobileH3">
                {t(item.title)}
              </Typography>
              <Typography component="p" variant="desktopBody2">
                {t(item.description)}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );

  if (forDrawer) return <Content />;
  return (
    <Container id="subscription-guide">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: {
            lg: "flex-start",
            md: "flex-start",
            sm: "center",
            xs: "center",
          },
          gap: {
            lg: 6,
            md: 6,
            sm: 5,
            xs: 5,
          },
          backgroundColor: theme.palette.background.paper,
          mt: 6,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: {
              lg: "space-between",
              md: "space-between",
              sm: "center",
              xs: "center",
            },
            alignItems: "center",
            gap: 3,
            width: "100%",
          }}
        >
          <Typography
            sx={{
              textAlign: {
                lg: "left",
                md: "left",
                sm: "center",
                xs: "center",
              },
            }}
            component="h2"
            variant={mediumBreakpoint ? "desktopH2" : "mobileH2"}
          >
            {t("membershipGuide.title")}
          </Typography>
          {showDetailsLink && (
            <Link href={`/know-us/how-it-works`}>
              <Typography
                sx={{
                  display: {
                    lg: "block",
                    md: "block",
                    sm: "none",
                    xs: "none",
                  },
                }}
              >
                {t("membershipGuide.link")}
              </Typography>
            </Link>
          )}
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "column",
              xs: "column",
            },
            alignItems: {
              lg: "flex-start",
              md: "flex-start",
              sm: "center",
              xs: "center",
            },
            justifyContent: {
              lg: "space-between",
              md: "center",
              sm: "flex-start",
              xs: "flex-start",
            },
            gap: {
              lg: 3,
              md: 3,
              sm: 5,
              xs: 5,
            },
          }}
        >
          <Content />
        </Box>
        <Box
          sx={{
            display: {
              lg: "none",
              md: "none",
              sm: "flex",
              xs: "flex",
            },
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: 2,
          }}
        ></Box>
      </Box>
    </Container>
  );
};
