import { Container, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { GetStaticProps } from "next";
import type { NextPage } from "next";
import { useTranslation } from "next-i18next";

import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import nextI18NextConfig from "next-i18next.config";
import path from "path";
import { CTABlog, CTADouble } from "src/components/Common/CTA";
import { MembershipGuide } from "src/components/Common/MembershipGuide";
import { TestimonialVideos } from "src/components/Common/TestimonialVideos";
import {
  Hero,
  Features,
  Why,
  ToTheRoad,
  PopularPackets,
  DonationCulture,
} from "src/components/Home";
import { PreviewProducts } from "src/components/Products";
import { PageSeo } from "src/core/seo/PageSeo";
import { HomeLayout } from "src/layouts/HomeLayout";

path.resolve("./next.config.js"); // https://github.com/nrwl/nx/issues/9017#issuecomment-1140066503

const HomePage: NextPage = () => {
  const { t } = useTranslation();

  return (
    <HomeLayout>
      <PageSeo titleTemplate="%s" />

      <Hero />
      <Features />

      <PreviewProducts
        title={t("home:products.title")}
        desc={t("home:products.desc")}
      />

      <Why />

      <ToTheRoad />

      <Container>
        <Divider />
      </Container>

      <Box py={6}>
        <MembershipGuide showDetailsLink={true} />
      </Box>

      <PopularPackets />

      <CTABlog />
      <DonationCulture />
      <TestimonialVideos title={t("common:if-you-happy-we-happy")} />

      <CTADouble variant={1} />
    </HomeLayout>
  );
};

export default HomePage;

// Initialize I18N
export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(
      locale!,
      ["home", "common", "nav", "footer", "auth", "cta", "packet", "cart"],
      nextI18NextConfig
    )),
  },
});
