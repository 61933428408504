import { Box } from "@mui/material";
import { FC } from "react";

import { Footer } from "src/components/Common/Footer";
import { NavigationHeader } from "src/components/Common/Navigation/NavigationHeader";

type Props = {
  children: React.ReactNode;
  invertHeader?: boolean;
};

export const HomeLayout: FC<Props> = ({ children, invertHeader }) => (
  <Box
    component="section"
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
      height: "100%",
    }}
  >
    <NavigationHeader invert={invertHeader} />

    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        flexGrow: 1,
        flexShrink: 0,
        paddingTop: invertHeader
          ? {
              xs: 8,
              md: 10,
            }
          : undefined,
      }}
    >
      {children}
    </Box>

    <Footer />
  </Box>
);
